



























































import Vue from 'vue'
import Component from 'vue-class-component'
import { Getter, Action } from 'vuex-class'
import globalMixins from '@/mixins/global.ts'
import bus from '@/common/bus'
import conf from '@/common/conf'

@Component({
  mixins: [globalMixins]
})
export default class Login extends Vue<globalMixins> {
  @Getter('account') account: Object
  @Getter('token') token: string
  @Getter('projects') projects: Object[]
  @Getter('otherProjects') otherProjects: Object[]
  @Action('login') login: Function
  
  checked: Boolean = true
  LoginForm: Object = {
    account: '',
    password: '',
    projects: []
  }
  logining: Boolean = false
  rule: object = {
    account: [
      {
        required: true,
        max: 20,
        min: 6,
        message: '用户名是必须的，长度为6-20位',
        trigger: 'blur'
      }
    ],
    password: [
      {
        required: true,
        message: '密码是必须的！',
        trigger: 'blur'
      }
    ]
  }
  created () {
  }
  submit () {
    let LoginForm = this.$refs.LoginForm as HTMLFormElement
    LoginForm.validate((valid: boolean) => {
      if (valid) {
        this.logining = true
        let params = {
          account: (this.LoginForm as any).account,
          password: (this.LoginForm as any).password,
          projects: (this.LoginForm as any).projects
        }
        this.login({ params }).then(() => {
          this.logining = false
          this.successMsg('登录成功！')
          let account: any = this.account
          let token = this.token
          this.logining = false
          // if (this.checked) {
          //   this.setCookie((this.LoginForm as any).account, 7)
          // } else {
          //   this.clearCookie()
          // }
          this.setCookie((this.LoginForm as any).account, 7)
          localStorage.setItem('account', JSON.stringify(account))
          localStorage.setItem('token', token)
          bus.$emit('surfaceChange')
          // let oneProjEnabled: boolean = false
          if (this.projects) {
            // 如果有projects并且只有一个课题
            if (this.projects.length === 1 && !account.is_staff) {
              let project = this.projects[0]
              localStorage.setItem('projects', JSON.stringify(project))
              this.$router.push('/dashboard')
            } else {
              this.$router.push('/topic-add')
            }
          } else {
            this.$router.push('/topic-add')
          }
        }).catch((error: any) => {
          this.logining = false
        })
      } else {
        this.logining = false
      }
    })
  }
  setCookie (account: string, days: any) {
    let date = new Date()
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days) // 保存的天数
    // 字符串拼接cookie
    window.document.cookie = 'account' + '=' + account + ';path=/;expires=' + date.toUTCString()
  }
    
  getCookie () {
    if (document.cookie.length > 0) {
      let arr = document.cookie.split('; ') // 显示的格式需要切割一下自己可输出看下
      for (let i = 0; i < arr.length; i++) {
        let arr2 = arr[i].split('=') // 再次切割
        if (arr2[0] === 'account') { // 判断查找相对应的值
          (this.LoginForm as any).account = arr2[1] // 保存到保存数据的地方
        }
      }
    }
  }

  clearCookie () { // 清除cookie
    this.setCookie('', -1) // 修改2值都为空，天数为负1天就好了
  }

  reset () {
    (<HTMLFormElement>this.$refs.LoginForm).resetFields()
  }
}
